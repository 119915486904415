<template>
  <div class="login">
    <div class="login-box">
      <h1>Login</h1>
      <form @submit.prevent="CurentLogin">
        <label>Email</label>
        <input
          type="email"
          placeholder="Email"
          v-model="loginForm.email"
          required
        />
        <label>Mot de passe</label>
        <input
          type="password"
          placeholder="Mot de passe"
          v-model="loginForm.password"
          required
        />
        <b-button
          variant="danger"
          type="submit"
          class="succes-btn-modal btn-danger"
        >
          <div class="block-spinner">
            <div v-if="getUserLoading" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
            <div v-else>Connexion</div>
          </div>
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      loginForm: {
        email: '',
        password: '',
      },
      spin: false,
      error: null,
    };
  },
  methods: {
    ...mapActions(['login']),
    async CurentLogin() {
      this.spin = true;
      this.payload = this.loginForm;
      await this.login(this.payload)
        .then(() => {
          this.spin = false;
          this.error = null;
        })
        .catch(() => {
          this.error = 'Impossible de se connecter avec ces informations';
        });
    },
  },
  computed: {
    ...mapGetters(['getUserLoading']),
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  @media only screen and (max-width: 900px) {
    width: 80%;
  }
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  border-radius: 3px;
  background-color: white;
}

h1 {
  text-align: center;
  padding-top: 15px;
}

form {
  width: 100%;
  padding: 0 15px 15px;
}

form label {
  display: flex;
  margin-top: 20px;
  font-size: 18px;
}

form input {
  width: 100%;
  padding: 7px;
  border: none;
  border: 1px solid gray;
  border-radius: 6px;
  outline: none;
}
input[type='button'],
button[type='submit'] {
  width: 80%;
  height: 45px;
  margin-top: 24px;
  padding: 0;
  border: none;
  border-radius: 6px;
  background-color: #e4261b;
  color: white;
  font-size: 18px;
}
button[type='submit'] {
  cursor: pointer;
}
p {
  text-align: center;
  padding-top: 20px;
  font-size: 15px;
}
</style>
